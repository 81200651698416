import TwoCirclesSpinner from 'components/Spinner/TwoCirclesSpinner'
import { FC, ReactNode, RefObject, SyntheticEvent } from 'react'
import styles from './clickable.module.css'
import { colorMediumGrayText } from 'styles/colors.module.css'
// import useHandlerForKeypress from 'hooks/useHandlerForKeypress'
import { useHotkeys } from 'react-hotkeys-hook'

type ClickableProps = {
  onClick: Function,
  children: ReactNode,
  className?: string,
  isBusy?: boolean,
  isBusyColor?: string,
  isDisabled?: boolean,
  title?: string,
}

const Clickable: FC<ClickableProps> = ({
  onClick,
  className,
  children,
  isBusy = false,
  isBusyColor = colorMediumGrayText,
  isDisabled = false,
  title,
}: ClickableProps) => {
  const maybeClick = (event: SyntheticEvent | MouseEvent | KeyboardEvent) => {
    !isDisabled && onClick(event)
    return false
  }

  const keyPressRef = useHotkeys('Space, Enter', maybeClick, [maybeClick])

  return <div
    onClick={maybeClick}
    className={`${styles.clickable}${className ? ' ' + className : ''}`}
    tabIndex={0}
    role='button'
    ref={keyPressRef as RefObject<HTMLDivElement>}
    title={title}
  >
    { isBusy ? <TwoCirclesSpinner color={isBusyColor} /> : children }
  </div>
}

export default Clickable
