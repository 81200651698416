import styles from './twoCirclesSpinner.module.css'
import { colorPurple } from 'styles/colors.module.css'

export type TwoCirclesSpinnerProps = {
  scale?: number,
  label?: string,
  color?: string,
}

const TwoCirclesSpinner = ({ scale = 1, label, color = colorPurple }: TwoCirclesSpinnerProps) =>
  <div className={styles.twoCirclesContainer}>
    <div className={styles.twoCircles} style={{ color, transform: `scale(${scale})`}}>
      <div></div>
      <div></div>
    </div>
    { label && <div className={styles.label}>{ label }</div> }
  </div>

export default TwoCirclesSpinner
