import PageForMarketing from 'components/PageForMarketing'
import PageWithTailwind from 'components/PageWithTailwind'
import LoginView from 'views/LoginView'

const LoginPage = () => <PageForMarketing>
  <PageWithTailwind title='Login'>
    <LoginView />
  </PageWithTailwind>
</PageForMarketing>

export default LoginPage
